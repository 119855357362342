import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core'
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from '@apollo/client/link/error'
import { logErrorMessages } from '@vue/apollo-util'
import { DefaultApolloClient } from '@vue/apollo-composable'
import Cookies from 'js-cookie'
import emitter from '@/mitt'
import { get, set, merge, unionBy } from 'lodash'

const errorLink = onError(error => {
	if (process.env.NODE_ENV !== 'production') logErrorMessages(error)
	const { networkError, graphQLErrors } = error
	if (networkError) {
		emitter.emit('error', networkError);
		switch (networkError.statusCode) {
			case 419: document.location.replace(`${process.env.VUE_APP_APP_ENDPOINT}/login/azure?r=${btoa(window.location.href)}`); break;
			default: emitter.emit('maintenance', networkError.message); break;
		}
	}
	if (graphQLErrors) {
		console.log(graphQLErrors)
		const authError = graphQLErrors.find(e => e.extensions.category === 'authentication')
		if (authError) document.location.replace(`${process.env.VUE_APP_APP_ENDPOINT}/login/azure?r=${btoa(window.location.href)}`);
	}
})

const httpLink = createUploadLink({
	uri: `${process.env.VUE_APP_APP_ENDPOINT}/graphql`,
	credentials: "include",
	headers: {
		"X-XSRF-TOKEN": Cookies.get('XSRF-TOKEN'),
		"accept": "application/json",
	}
})

export const apolloClient = new ApolloClient({
	link: ApolloLink.from([errorLink, httpLink]),
	cache: new InMemoryCache()
})

export { DefaultApolloClient }

export function deleteCacheItem(model) {
	const normalizedId = apolloClient.cache.identify({
		id: model.id,
		__typename: model.__typename
	});
	apolloClient.cache.evict({ id: normalizedId });
	apolloClient.cache.gc();
}

export function pushCacheArrayItem(model, query, variables, path) {
	let sourceData = apolloClient.cache.readQuery({ query, variables })
	let sourceArray = get(sourceData, path)
	let updatedArray = unionBy(sourceArray, [model], 'id')
	let updatedData = {}
	set(updatedData, path, updatedArray)
	
	apolloClient.cache.writeQuery({ query, variables, data: merge(updatedData, sourceData) })
	apolloClient.cache.gc();
}
