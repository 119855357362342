<template>
    <div class="flix-text--small">
        <span class="flix-space-1-right">{{ moment(activity.created_at).fromNow() }}</span>
        <img v-if="withCauserUserpic && user.userpic" :src="user.userpic.original_url" class="exp-userpic-18"/>
        <b class="flix-space-1-right">{{ user.name }}</b>
        <span v-if="activity.description==='created'" class="flix-space-1-right">joined Flixperiments</span>
        <span v-if="activity.description==='updated'" class="flix-space-1-right">updated profile</span>
        <hr class="flix-divider flix-space-2-top"/>
    </div>
</template>

<script setup>
import { inject } from 'vue'
const moment = inject('moment')
defineProps({
    activity: { type: Object, required: true },
    user: { type: Object, required: true },
    causer: { type: Object },
    withCauserUserpic: { type: Boolean, default: true },
    withSubject: { type: Boolean, default: true },
})
</script>
