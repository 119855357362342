<template>
    <div class="flix-notification-container flix-notification-container--bottom-right"
         :key="time"
    >
        <section id="notification-toast"
                 class="flix-notification flix-notification--toast"
                 :class="[notificationClass]"
                 role="alert"
                 aria-live="polite"
        >
            <div class="flix-notification__content">{{ notificationText }}</div>
        </section>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue'

const emitter = inject('emitter')
const notificationClass = ref('flix-hidden')
const notificationText = ref('')
const time = ref(Date.now())
const resetClass = () => setTimeout(() => notificationClass.value = 'flix-hidden', 4000)

emitter.on('error', error => {
    notificationClass.value = 'flix-notification--danger'
    notificationText.value = error
    time.value = Date.now()
    console.error(error)
    resetClass()
})

emitter.on('success', text => {
    notificationClass.value = 'flix-notification--success'
    notificationText.value = text
    time.value = Date.now()
    resetClass()
})
</script>

<style scoped>

</style>
