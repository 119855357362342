<template>
    <div>
        <button type="button"
                class="flix-btn flix-btn--primary flix-btn--square flix-space-xs-left exp-float-right"
                :class="{'flix-btn--loading': loading}"
                :disabled="loading"
                @click="mutate"
        >
            <i v-if="!loading" class="flix-btn__icon flix-icon flix-icon-check-solid" />
        </button>
        <span class="flix-space-1-right">{{ moment(notification.created_at).fromNow() }} </span>
        <span v-if="notification.type==='App\\Notifications\\UserJoined'">
            <b>Hi there!</b> Thanks for joining Flixperiments!
            <span>Acknowledge this message by clicking the <icon-component icon="check" solid /> button</span>
        </span>
        <span v-else-if="notification.data?.model === 'Experiment'">
            <b class="flix-space-1-right">{{ message }}</b>
            <a v-if="!notification.data.id"
               class="flix-link"
               :href="notification.data.url"
               v-text="notification.data.title"
            />
            <router-link v-else
                         class="flix-link"
                         :to="{name: 'viewExperiment', params: { id: notification.data.id }}"
            >
                {{ notification.data.title }}
            </router-link>
        </span>
        <hr class="flix-divider flix-space-2-top flix-space-2-bottom"/>
    </div>
</template>

<script setup>
import IconComponent from '@/components/IconComponent'
import { inject, toRefs, computed } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { acknowledgeNotification } from '@/graphql/user.graphql'
import { deleteCacheItem } from '@/apollo'
const moment = inject('moment')
const emitter = inject('emitter')

const props = defineProps({
    notification: Object
})
const { notification } = toRefs(props)

const message = computed(
	() => {
		switch(notification.value.type) {
            case 'App\\Notifications\\ExperimentAssigned': return 'you are in charge for'
            case 'App\\Notifications\\ExperimentShouldGoLive': return 'experiment should GoLive'
            case 'App\\Notifications\\ExperimentShouldBeChecked': return 'check the intermediate data'
            case 'App\\Notifications\\ExperimentShouldBeStopped': return 'experiment should be stopped'
            case 'App\\Notifications\\ExperimentShouldBeAnalyzed': return 'time to analyze'
            case 'App\\Notifications\\ExperimentCommented': return 'someone commented on'
            default: return ''
        }
    }
)

const { mutate, loading, onDone } = useMutation(acknowledgeNotification, () => ({
	variables: notification.value
}))

onDone(({ data }) => {
	deleteCacheItem(data.acknowledgeNotification)
	emitter.emit('success', 'Notification acknowledged')
})
</script>

<style scoped>

</style>
