<template>
    <panel-component title="Activity Log"
                     :visible="visible"
                     @update:visible="emit('update:visible', $event)"
    >
        <div v-if="loading" aria-busy="true" aria-live="polite" class="exp-loader">
            <div class="flix-spinner flix-spinner--xxl" />
        </div>
        <activity-list-component v-else :activities="activities" />
    </panel-component>
</template>

<script setup>
import { computed, inject, watch, toRefs } from 'vue'
import gql from '@/graphql/user.graphql'
import PanelComponent from '@/components/PanelComponent'
import { useLazyQuery } from '@vue/apollo-composable'
import { pushCacheArrayItem } from '@/apollo'
import { orderBy } from 'lodash'
import ActivityListComponent from '@/components/Activity/ActivityListComponent'

const emitter = inject('emitter')
const props = defineProps({
	visible: { type: Boolean, required: true }
})
const { visible } = toRefs(props)
const emit = defineEmits(['update:visible'])

const { result, loading, load } = useLazyQuery(gql.activities,
	{ limit: 50 },
	{ fetchPolicy: 'network-only' }
)
const activities = computed(() => {
	let activities = result.value?.activities.filter(a => a.causer) ?? []
	return orderBy(activities, ['created_at'], ['desc'])
})
watch(
	() => visible,
    visible => { if (visible.value) load() },
	{ immediate: true, deep: true }
)

const onActivityCreated = activity => pushCacheArrayItem(
	activity,
	gql.activities,
	{ limit: 50 },
	'activities'
)

emitter.on('modelCreated:Activity', model => onActivityCreated(model))
</script>

<style scoped>
.exp-loader {
    margin: 0 auto;
}
</style>
