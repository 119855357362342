<template>
    <panel-component title="Notifications"
                     :visible="visible"
                     @update:visible="emit('update:visible', $event)"
    >
        <div v-if="loading" aria-busy="true" aria-live="polite" class="exp-loader">
            <div class="flix-spinner flix-spinner--xxl" />
        </div>
        <div v-else-if="!notifications.length" class="flix-infobox flix-infobox--success">
            <div class="flix-infobox__content">You have no unread notifications.</div>
        </div>
        <div v-else>
            <notifications-list-item-component v-for="item in notifications" :key="item.id" :notification="item" />
        </div>
    </panel-component>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import { computed, inject, watch, toRefs } from 'vue'
import gql from '@/graphql/user.graphql'
import PanelComponent from '@/components/PanelComponent'
import { useLazyQuery } from '@vue/apollo-composable'
// import { pushCacheArrayItem } from '@/apollo'
import { orderBy } from 'lodash'
import NotificationsListItemComponent from '@/components/Notifications/NotificationsListItemComponent'

// const emitter = inject('emitter')
const props = defineProps({
	visible: { type: Boolean, required: true }
})
const { visible } = toRefs(props)
const emit = defineEmits(['update:visible'])

const { result, loading, load } = useLazyQuery(gql.notifications,
	{ unread: true },
	{ fetchPolicy: 'network-only' }
)
const notifications = computed(() => orderBy(result.value?.notifications, ['created_at'], ['desc']) ?? [])
watch(
	() => visible,
    visible => { if (visible.value) load() },
	{ immediate: true, deep: true }
)

/*
const onActivityCreated = activity => pushCacheArrayItem(
	activity,
	gql.activities,
	{ limit: 50 },
	'activities'
)

emitter.on('modelCreated:Activity', model => onActivityCreated(model))
 */
</script>

<style scoped>
.exp-loader {
    margin: 0 auto;
}
</style>
