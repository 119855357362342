import { createApp } from 'vue'

import App from '@/App.vue'
import { apolloClient, DefaultApolloClient } from '@/apollo'
import { router } from '@/router'
import emitter from '@/mitt'
import moment from 'moment'
import VueViewer from 'v-viewer'

const app = createApp(App)
app.use(router)
app.use(VueViewer)
app.provide('emitter', emitter)
app.provide('moment', moment)
app.config.errorHandler = e => emitter.emit('error', e)
app.provide(DefaultApolloClient, apolloClient)
app.mount('#app')
