<template>
    <div v-if="maintenance" class="centered">
        <div class="flix-infobox flix-infobox--danger">
            <div class="flix-infobox__content">
                <p class="flix-text">{{ maintenance }}</p>
                <button type="button" class="flix-btn flix-btn--block" @click="reload">Reload page</button>
            </div>
        </div>
    </div>
    <div v-else-if="loading" aria-busy="true" aria-live="polite" class="centered">
        <div class="flix-spinner flix-spinner--xxl" />
    </div>
    <template v-else-if="error">
        Error
    </template>
    <template v-else>
        <header-component />
        <section class="flix-page-container flix-page-container--has-fixed-header">
            <router-view :key="$route.path" />
        </section>
        <footer-component />
    </template>
    <notification-component />
</template>

<script setup>
import {inject, ref, provide, computed, watch} from 'vue'
import { useTitle } from '@vueuse/core'
// import datadogRum from '@/datadog'

import HeaderComponent from "@/components/HeaderComponent";

import { useQuery } from "@vue/apollo-composable";
import gql from "@/graphql/user.graphql"
import FooterComponent from '@/components/FooterComponent'
import NotificationComponent from "@/components/NotificationComponent";

const emitter = inject('emitter')
let maintenance = ref(false)
emitter.on('maintenance', e => maintenance.value = e)
emitter.on('overlay', visible => document.body.style.overflow = visible ? 'hidden' : 'auto')

let { result, loading, error } = useQuery(gql.me)
const me = computed(() => ({ ...result.value?.me }))
provide('me', me)

const reload = () => document.location.reload()

watch(() => me.value.theme, (theme, oldtheme) => {
	if (theme) document.body.classList.add(theme)
	if (oldtheme) document.body.classList.remove(oldtheme)
})

useTitle(process.env.VUE_APP_TITLE)

// if (process.env.NODE_ENV === 'production') datadogRum.startSessionReplayRecording()
/*
watch(() => me.value, me => {
	datadogRum.setUser({
        id: me.id,
        // name: me.name,
        // email: me.email,
    })
}, { immediate: true, deep: true });
*/
</script>

<style lang="scss">
@import '~@flixbus/honeycomb/assets/scss/honeycomb';
@import '~@flixbus/honeycomb-icons-static/dist/fonts/flix-icon.css';

@import "~assets/css/awesomplete";
@import '~viewerjs/dist/viewer.css';

html { height: 100%; }

body::after {
  content: '';
  display: block;
  height: cssvar(spacing-7);
}

.cursor-pointer { cursor: pointer; }

a {
  color: cssvar(link-color);
}

.centered {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.exp-float-right {
  float: right;
}

.exp-icon {
  width: 20px;
  height: 20px;
  margin: 0 6px;
  vertical-align: text-bottom;
  color: cssvar(primary-bg-color);
}

.exp-loader {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 300px auto 100px;
  justify-content: center;
  align-items: center;
}

#app {
  --flix-page-max-width: 1560px !important;
  --flix-primary-box-shadow: 0 0 12px rgba(0,0,0,0.12);
  --exp-color-purple: #9b59b6;
}

.exp-metric-off {
  color: cssvar(grayscale-30-color);
  font-weight: normal;
}

.exp-metric-pos {
  color: cssvar(success-color);
}

.exp-metric-neg {
  color: cssvar(danger-color);
}

.exp-metric-con {
  color: cssvar(content-secondary-color);
}

.exp-metric-p {
  margin: 0 0 0 cssvar(spacing-1);
  font-size: smaller;
}

.exp-status-purple { background: var(--exp-color-purple) !important; border-color: var(--exp-color-purple) !important; }
.exp-status-blue { background: var(--flix-neutral-color) !important; border-color: var(--flix-neutral-color)  !important; }
.exp-status-orange { background: var(--flix-warning-color) !important; border-color: var(--flix-warning-color)  !important; }
.exp-status-yellow { background: var(--flix-warning-color) !important; border-color: var(--flix-warning-color)  !important; }
.exp-status-green { background: var(--flix-success-dark-color) !important; border-color: var(--flix-success-dark-color) !important; }
.exp-status-red { background: var(--flix-danger-dark-color) !important; border-color: var(--flix-danger-dark-color)  !important; }
.exp-status-grey { background: var(--flix-grayscale-50-color) !important; border-color: var(--flix-grayscale-50-color)  !important; }

.exp-userpic-18 {
  width: cssvar(spacing-3);
  height: cssvar(spacing-3);
  border-radius: calc(var(--flix-spacing-3) / 2);
  margin-right: cssvar(spacing-1);
  vertical-align: text-top;
}

.exp-box-duration {
  width: 100%;
  min-width: var(--flix-spacing-12);
  height: var(--flix-spacing-1);
  border-radius: var(--flix-spacing-half);
  background: var(--flix-grayscale-30-color);
  overflow: hidden;
}

.exp-box-duration-value {
  height: 100%;
}
</style>
