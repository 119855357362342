<template>
    <div class="flix-text--small">
        <span class="flix-space-1-right">{{ moment(activity.created_at).fromNow() }}</span>
        <img v-if="withCauserUserpic && causer.userpic" :src="causer.userpic.original_url" class="exp-userpic-18"/>
        <b class="flix-space-1-right">{{ causer.name }}</b>
        <span class="flix-space-1-right">{{ activity.description }}</span>
        <br/>
        <span v-if="!epic" class="flix-space-1-right">
            <u v-text="`Epic: ${activity.subject_id}`"/>
            <icon-component icon="delete" :size="3" />
        </span>
        <router-link v-else-if="withSubject"
                     class="flix-link flix-space-1-right"
                     :to="{name: 'viewEpic', params: { slug: epic.slug }}"
        >
            {{ epic.title }}
        </router-link>
        <hr class="flix-divider flix-space-2-top"/>
    </div>
</template>

<script setup>
import { inject } from 'vue'
import IconComponent from '@/components/IconComponent'
const moment = inject('moment')
defineProps({
    activity: { type: Object, required: true },
    epic: { required: true },
    causer: { type: Object, required: true },
    withCauserUserpic: { type: Boolean, default: true },
    withSubject: { type: Boolean, default: true },
})
</script>
