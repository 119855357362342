<template>
  <div class="flix-panel" :class="{'flix-panel--active': visible}">
    <div class="flix-panel__body">
        <div class="flix-panel__header">
            <h3 class="flix-h3 flix-panel__title" v-text="title" />
            <button aria-label="Close panel" class="flix-panel__close flix-btn flix-btn--square flix-btn--link" @click.prevent="close" />
        </div>
        <div class="flix-panel__content">
            <slot/>
        </div>
        <div class="flix-panel__footer">
            <button class="flix-btn flix-btn--primary flix-btn--block" @click.prevent="close" v-text="`Close`" />
        </div>
    </div>
    <div class="flix-panel__overlay flix-overlay" @click.prevent="close" />
  </div>
</template>

<script setup>
import { inject, watch } from 'vue'
const emitter = inject('emitter')

const props = defineProps({
	visible: { type: Boolean, default: false },
    title: { type: String },
})

const emit = defineEmits(['update:visible'])
const close = () => emit('update:visible', false)

watch(() => props.visible,v => emitter.emit('overlay', v))
</script>
