<template>
    <header class="flix-header flix-header--fixed">
        <div class="flix-header__inner">

            <div class="flix-header-brand flix-header-brand--square">
                <router-link class="flix-header-brand__link" :to="{ name: 'index' }">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="180 180 1630 1630"
                         class="flix-header-brand__img"
                         width="36"
                         height="36"
                    >
                        <path d="M1360 1621 l0 -80 -37 -6 c-144 -24 -222 -73 -393 -246 -80 -80 -161 -155 -180 -165 -31 -17 -63 -19 -303 -22 l-267 -3 0 -105 0 -104 265 0 c251 0 268 -1 307 -21 23 -12 105 -85 182 -164 124 -125 151 -147 224 -183 45 -23 109 -46 142 -53 l59 -11 3 -79 3 -78 213 123 c117 67 217 127 223 133 7 7 -61 51 -209 136 -121 70 -223 127 -226 127 -3 0 -6 -34 -6 -75 0 -86 -4 -89 -79 -54 -68 30 -127 80 -240 200 l-104 111 134 134 c130 131 187 172 262 190 l27 6 0 -76 c0 -42 3 -76 6 -76 11 0 444 252 444 259 -1 3 -76 49 -167 101 -92 52 -193 110 -225 128 l-58 33 0 -80z" fill="#353535" />
                    </svg>

                </router-link>
            </div>

            <nav class="flix-header-nav" aria-label="Main">
                <ul class="flix-header-nav__list">
                    <li v-for="link in links" :key="link.route" class="flix-header-nav__item">
                        <router-link :to="{name: link.route}"
                                     class="flix-header-nav__link"
                                     active-class="flix-header-nav__link--active"
                                     @click="navigation = false"
                        >
                            {{link.name }}
                        </router-link>
                    </li>
                </ul>
            </nav>


            <div class="flix-header-burger-menu">
                <button type="button"
                        aria-label="Open main site navigation"
                        class="flix-header-burger-menu__toggle flix-btn flix-btn--link flix-btn--square flix-btn--md"
                        aria-controls="menu-panel"
                        :aria-expanded="navigation ? 'true' : 'false'"
                        @click="navigation = !navigation"
                />
                <div id="menu-panel" class="flix-header-burger-menu__panel" :hidden="navigation ? null : ''">
                    <button type="button"
                            aria-label="Close main site navigation"
                            class="flix-header-burger-menu__toggle flix-btn flix-btn--link flix-btn--square flix-btn--md"
                            aria-controls="menu-panel"
                            :aria-expanded="navigation ? 'true' : 'false'"
                            @click="navigation = !navigation"
                    />

                    <nav class="flix-header-burger-menu__nav" aria-label="Main">
                        <ul class="flix-header-burger-menu__list">
                            <li v-for="link in links" :key="link.route" class="flix-header-burger-menu__item">
                                <router-link :to="{name: link.route}"
                                             class="flix-header-burger-menu__link"
                                             active-class="flix-header-burger-menu__link--active"
                                             @click="navigation = false"
                                >
                                    <span class="flix-header-burger-menu__text">{{ link.name }}</span>
                                </router-link>
                            </li>
                            <li class="flix-header-burger-menu__item">
                                <router-link :to="{name: 'profile'}"
                                             class="flix-header-burger-menu__link"
                                             active-class="flix-header-burger-menu__link--active"
                                             @click="navigation = false"
                                >
                                    <span class="flix-header-burger-menu__text">Profile</span>
                                </router-link>
                            </li>
                        </ul>
                    </nav>

                </div>
                <div class="flix-header-burger-menu__overlay flix-overlay"></div>
            </div>


            <div class="flix-header-widgets">
                <nav class="flix-header-user-widget">
<!--                    <search-component />-->
                    <button type="button"
                            class="flix-header-user-widget__toggle flix-btn flix-btn--has-icon flix-btn--tertiary flix-btn--square flix-hidden-xs flix-hidden-sm"
                            @click.prevent="activity = true"
                    >
                        <icon-component icon="collaboration" solid :size="4" color="var(--flix-header-color)" />
                    </button>
                    <button type="button"
                            class="flix-header-user-widget__toggle flix-btn flix-btn--has-icon flix-btn--tertiary flix-btn--square"
                            @click.prevent="notifications = true"
                    >
                        <icon-component icon="newsletter" solid :size="4" color="var(--flix-header-color)" />
                    </button>
                    <router-link class="flix-avatar flix-header-user-widget__avatar flix-space-2-left" :to="{ name: 'profile' }">
                        <img class="flix-avatar__image" :src="me.userpic.original_url" :alt="me.name"/>
                    </router-link>
                </nav>
            </div>

        </div>
    </header>

    <activity-panel v-model:visible="activity" />
    <notifications-panel v-model:visible="notifications" />
</template>

<script setup>
import { inject, ref} from "vue"
import IconComponent from '@/components/IconComponent'
import ActivityPanel from '@/components/Panel/ActivityPanel'
import NotificationsPanel from '@/components/Panel/NotificationsPanel'
// import SearchComponent from '@/components/SearchComponent.vue'

const me = inject('me')
const navigation = ref(false)
const activity = ref(false)
const notifications = ref(false)

const links = [
	{ route: 'experiments', name: 'Experiments' },
	{ route: 'timelineExperiments', name: 'Timeline' },
	{ route: 'flowExperiments', name: 'Flow' },
	{ route: 'epics', name: 'Epics' },
	{ route: 'createExperiment', name: 'Create' },
]
</script>

<style lang="scss" scoped>
.flix-header-brand__link path {
    fill: var(--flix-header-color);
}
</style>
