import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{ path: '/', redirect: { name: 'experiments' } , name: "index" },
	{ path: '/profile', component: () => import('./views/updateProfile'), name: "profile" },
	
	{ path: '/experiments', component: () => import('./views/listExperiments'), name: "experiments" },
	{ path: '/experiments/create', component: () => import('./views/createExperiment'), name: "createExperiment" },
	{ path: '/experiments/timeline', component: () => import('./views/timelineExperiments'), name: "timelineExperiments" },
	{ path: '/experiments/:id', component: () => import('./views/viewExperiment'), name: "viewExperiment" },
	{ path: '/experiments/:id/edit', component: () => import('./views/updateExperiment'), name: "updateExperiment" },
	{ path: '/experiments/flow', component: () => import('./views/flowExperiments.vue'), name: "flowExperiments" },
	
	{ path: '/epics', component: () => import('./views/listEpics'), name: "epics" },
	{ path: '/epics/create', component: () => import('./views/createEpic'), name: "createEpic" },
	{ path: '/epics/:slug', component: () => import('./views/viewEpic'), name: "viewEpic" },
	{ path: '/epics/:slug/edit', component: () => import('./views/updateEpic'), name: "updateEpic" },
]

export const router = createRouter({ history: createWebHistory(), routes })
